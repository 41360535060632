import { useLocation } from '@reach/router';
import getQueryValue from '@invitato/helpers/dist/getQueryStringValue';

/**
 * Custom hook to return GuestName
 * @return {string}
 */
function useGuestName() {
  const location = useLocation();
  const name = getQueryValue(location, 'to');
  const finalName = decodeURIComponent(name);

  return finalName;
}

export default useGuestName;
