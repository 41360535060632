import { useLocation } from '@reach/router';
import { ENABLE_ANNOUNCEMENT_MODE } from '../constants/features-flag';
import getQueryValue from '@invitato/helpers/dist/getQueryStringValue';

/**
 * Custom hook to return GuestName
 * @return {string}
 */
function useInvitation() {
  if (!ENABLE_ANNOUNCEMENT_MODE) return true;

  const location = useLocation();
  const type = getQueryValue(location, 'type');

  return type === 'invitation';
}

export default useInvitation;
